import { Button, Heading, Link } from '@troon/ui';
import { ErrorBoundary as SolidErrorBoundary, Show, createMemo } from 'solid-js';
import { HttpStatusCode } from '@solidjs/start';
import { captureException } from '@sentry/solidstart';
import { ApiError } from '@troon/api-client';
import { Navigate, useLocation } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export function ErrorBoundary(
	props: Omit<ComponentProps<typeof SolidErrorBoundary>, 'fallback'> & {
		contained?: boolean;
		fallback?: ComponentProps<typeof SolidErrorBoundary>['fallback'];
	},
) {
	const location = useLocation();
	return (
		<SolidErrorBoundary
			fallback={(error) => {
				captureException(error);

				if (error instanceof ApiError && error.statusCode === 401) {
					return <Navigate href={`/auth/login${location.search}`} />;
				}

				const title = createMemo(() => {
					if (error && !isNaN(error.statusCode)) {
						if (error.statusCode === 404) {
							return 'Error 404 not found';
						}

						if (error.statusCode <= 403 && error.statusCode >= 400) {
							return 'Not authorized';
						}
					}
					return error.error?.message ?? 'An error has occurred';
				});

				return (
					<div class={props.contained ? 'container m-auto flex flex-col justify-center' : undefined}>
						<HttpStatusCode code={error.statusCode ?? 500} />
						<Heading as="h1" class="text-red-600 dark:text-red-500">
							{title()}
						</Heading>
						<p class="mb-4">
							<Show when={error.message} fallback="Something went wrong.">
								{error.message}
							</Show>
						</p>
						{process.env.NODE_ENV !== 'production' ? (
							<pre class="mb-4 overflow-x-auto rounded-md bg-brand-900 p-4 font-mono text-neutral-100">
								{(error as Error).stack ?? error.toString()}
							</pre>
						) : null}
						<div class="flex flex-row">
							<div class="flex flex-row gap-4">
								<Show when={!error.statusCode || error.statusCode === 500}>
									<Button
										onClick={() => {
											window.location.reload();
										}}
									>
										Reload
									</Button>
								</Show>
								<Button appearance="secondary" as={Link} href="/" target="_self">
									Return home
								</Button>
							</div>
						</div>
					</div>
				);
			}}
		>
			{props.children}
		</SolidErrorBoundary>
	);
}
